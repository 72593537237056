/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                // NAVIGATION
                $(function () {
                    $.scrollIt();
                });
                // END NAVIGATION

                // TYPED
                CustomTyped = function (opts) {
                    this.element = jQuery(opts.element);
                    this.strings = opts.strings;
                    this.index = 0;
                    jQuery(this.element).typed({
                        strings: [opts.strings[0]],
                        typeSpeed: -25,
                        backSpeed: -14000,
                        loop: false,
                        backDelay: 14000,
                        showCursor: false
                    });
                    var self = this;
                    jQuery(opts.next_button).click(function () {
                        self.next();
                    });
                };
                CustomTyped.prototype = {
                    update_text: function (str) {
                        jQuery(this.element).typed({
                            strings: [str],
                            typeSpeed: -25,
                            backSpeed: -14000,
                            loop: false,
                            backDelay: 14000,
                            showCursor: false
                        });
                    },
                    next: function () {
                        this.index = this.index + 1;
                        if (this.index === this.strings.length) {
                            this.index = 0;
                        }
                        this.update_text(this.strings[this.index]);
                    }
                };
                jQuery(function ($) {
                    typed_1 = new CustomTyped({
                        element: '.t-hulpvragen-1',
                        strings: ["We’re often blind to our own qualities and particularities as we take them for granted. What would the people around you say when asked about how you stand out and how you differentiate yourself from others?", "Which achievements are you really proud of? And which unique skills did you use to get there?", "Are there particular compliments that you get often – what other compliments would you like to receive more?", "When do friends call for your help or come to you?", "When do you secretly think: am I really this good, or is it you that’s so stupid?", "What do you have a special gift for?", "What qualities do people envy in you?"],
                        next_button: '#typed_1_button'
                    });
                    typed_2 = new CustomTyped({
                        element: '.t-hulpvragen-2',
                        strings: ["Which activities can consume you completely?", "Can you think of moments people followed your lead?", "Who are you with when you reach a state of flow: are you alone or with others?", "What happens to you (physically) when you are in flow? (so you can recognize it next time!)", "Think back at times you were in flow, what were you doing, where were you and with whom? What did these situations have in common?"],
                        next_button: '#typed_2_button'
                    });
                    typed_3 = new CustomTyped({
                        element: '.t-hulpvragen-3',
                        strings: ["What do you want your colleagues to be like?", "How much money do you want to make?", "Where do you want to live?", "Where do you want to work? In which location and what does your place to work look like?", "What industry or which field of work are you intrinsically drawn to?", "Do you want to regularly travel for your work, live a prolonged period abroad, work with people from different nationalities?", "How important is career growth to you? What possibilities for advancement do you seek in your job?", "How much mentorship do you need? Is peer-to-peer coaching between colleagues enough or do you also want to be surrounded with people with more experience than you?", "What’s the work-life balance like? How many hours are you willing to work on your job each week? How flexible do you want to be when it comes to when you work? How many holidays do you need each year?", "How important is autonomy to you?", "With which types of stakeholders do you want to work (e.g. individuals, governments or businesses, young or old people, many or few…)?", "What do your loved ones (e.g. your partner, children, broader family, friends) need from you in order to be happy?"],
                        next_button: '#typed_3_button'
                    });
                    typed_4 = new CustomTyped({
                        element: '.t-hulpvragen-4',
                        strings: ["Often, children live closer to their hearts than their adult selves. So if you still can, ask your parents how you were as a child. What kind of things did you love to do? Were you often alone, who were your friends and what did you excel in? Did you have certain dreams about the future?", "Visions of a distant future are just the end of what’s often a very long and tough journey. As the journey is more important now: what pain are you willing to accept in your life? What are you willing to fight for?", "If you knew you would die one year from now, what would you do and how would you want to be remembered?", "Which values are important to you?", "What is it that (only) you can give to the world, or would like to give?", "Which idea keeps coming back to you that you are not following up on?", "When do you consider your life successful?", "You change, but certain things about you remain the same over the years. Which activities have always made up an important part of your life?", "What can really touch you, make you emotional?"],
                        next_button: '#typed_4_button'
                    });
                    // END TYPED
                });

                //SCROLL on next button + 100 px
                //                jQuery(function ($) {
                //                    new Waypoint({
                //                        element: document.getElementById("scroll-next"),
                //                        offset: $(window).height() + $("#scroll-next").height() - 100,
                //                        handler: function (direction) {
                //                            if (direction === "down") {
                //                                console.log(this);
                //                                $(this.element).trigger('click');
                //                            }
                //                        }
                //                    });
                //                    next_scroll_buttons = document.getElementsByClassName("scroll-next");
                //                    $.each(next_scroll_buttons, function (index, element) {
                //                        return new Waypoint({
                //                            element: element,
                //                            offset: $(window).height() + $(element).height() - 100,
                //                            handler: function (direction) {
                //                                if (direction === "down") {
                //                                    $(this.element).trigger('click');
                //                                }
                //                            }
                //                        });
                //                    });
                //                });
                //END SCROLL 

                //SCROLL on next button + 100 px
                jQuery(function ($) {
                    new Waypoint({
                        element: document.getElementById("scroll-next"),
                        offset: $(window).height() + $("#scroll-next").height() - 300,
                        handler: function (direction) {
                            if (direction === "down") {
                                console.log(this);
                                $(this.element).trigger('click');
                            }
                        }
                    });
                    next_scroll_buttons = document.getElementsByClassName("scroll-next");
                    $.each(next_scroll_buttons, function (index, element) {
                        return new Waypoint({
                            element: element,
                            offset: $(window).height() + $(element).height() - 300,
                            handler: function (direction) {
                                if (direction === "down") {
                                    $(this.element).trigger('click');
                                }
                            }
                        });
                    });
                });
                //END SCROLL 

                //VUE
                var vue = new Vue({
                    el: '#container',
                    data: {
                        edge: [],
                        flow: [],
                        context: [],
                        purpose: [],
                        email: '',
                        send_mail_button_message: 'MAIL RESULTS',
                        mail_sent: false,
                    },
                    computed: {},
                    methods: {
                        validate: function () {
                            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                            return re.test(this.email);
                        },
                        send_email: function () {
                            if (!this.mail_sent) {
                                data = {
                                    action: 'pph_send_email',
                                    results: JSON.stringify({
                                        edge: this.edge,
                                        flow: this.flow,
                                        context: this.context,
                                        purpose: this.purpose,
                                        email: this.email,
                                    })
                                };
                                if (this.validate()) {
                                    self = this;
                                    ajax = jQuery.post(ajaxurl, data);
                                    ajax.then(
                                        function (response) { /* Success */
                                            self.mail_sent = true;
                                            self.send_mail_button_message = "CHECK YOUR INBOX!";
                                            console.log(response);
                                        },
                                        function (response) { /* Fail */
                                            self.send_mail_button_message = "PLEASE TRY AGAIN";
                                            console.log(response);
                                        }
                                    );
                                } else {
                                    this.send_mail_button_message = 'PLEASE TRY AGAIN';
                                }
                            }
                        },
                    }
                });

                //END VUE

                // INIT HAMBURGLER
                document.getElementById('hamburgler').addEventListener('click', checkNav);
                window.addEventListener("keyup", function (e) {
                    if (e.keyCode == 27) closeNav();
                }, false);

                function checkNav() {
                    if (document.body.classList.contains('hamburgler-active')) {
                        closeNav();
                    } else {
                        openNav();
                    }
                }

                function closeNav() {
                    document.body.classList.remove('hamburgler-active');
                }

                function openNav() {
                    document.body.classList.add('hamburgler-active');
                }
                // END HAMBURGLER

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.